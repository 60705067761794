<template>
  <div id="stock">
    <div class="header">
      <h1 style="font-size: 28px; color: #333;">주식 현재가 조회</h1>
    </div>

    <div class="stock-content">
      <input type="text" id="stockCode" placeholder="주식 종목 코드를 입력하세요">
      <button id="btnSearch" @click="getStockPrice()">조회</button>
      <p id="stockPrice">여기에 현재가가 표시됩니다.</p>
    </div>
    <br>
    <div id="history">
      <h2>조회 히스토리</h2>
      <button id="resetButton" @click="resetLocalStorage()">로컬 스토리지 리셋</button>
      <ul id="historyList"></ul>
    </div>
  </div>
  <div class="chart-container">
    <div id="chartContainer">
      <canvas id="historyChart"></canvas>
    </div>
  </div>
</template>

<script>
// import { useMeta } from 'vue-meta'
import { inject } from 'vue'
    
export default {
  setup() {
    
    const setMeta = inject('setMeta')
    setMeta({
      title: 'SoSoCEO - 사업하는 개발자',
      keywords: '주식,투자,sosoceo,재테크,돈,경제,주식투자,money',
      description: '주식 투자 자동화를 위한 HTML 페이지입니다.',
      'og:title': 'SoSoCEO - 사업하는 개발자',
      'og:description': '주식 투자 자동화를 위한 HTML 페이지입니다.',
      'og:image': 'https://geek9.kr/img/logo_big.e0186dc8.jpg',
      'og:url': 'https://geek9.kr/stock',
      'canonical': 'https://geek9.kr/stock'
    })
    // useMeta( {
    //   title: 'SoSoCEO - 사업하는 개발자',
    //   link: [{rel: "canonical", href: 'https://geek9.kr/stock'}],
    //   meta: [
    //     { vmid: 'charset_tstock', charset: 'utf-8' },
    //     { vmid: 'http-equiv_tstock', 'http-equiv': 'X-UA-Compatible', content: 'IE=edge,chrome=1' },
    //     { vmid: 'company_tstock', name: 'company', itemprop: 'name', content: 'GEEK9' },
    //     { vmid: 'keywords_tstock', name: 'keywords', content:'주식,투자,sosoceo,재테크,돈,경제,주식투자,money' },
    //     { vmid: 'viewport_tstock', name: 'viewport', content: 'width=device-width' },
    //     { vmid: 'description_tstock', name: 'description', content: '주식 투자 자동화를 위한 HTML 페이지입니다.' },
    //     { vmid: 'theme-color_tstock', name: 'theme-color', content: '#ffa07a'},
    //     { vmid: 'og:site_name_tstock', property: 'og:site_name', content: 'GEEK9'},
    //     { vmid: 'og:description_tstock', property: 'og:description', content: '주식 투자 자동화를 위한 HTML 페이지입니다.'},
    //     { vmid: 'og:title_tstock', property: 'og:title', content: 'SoSoCEO - 사업하는 개발자'},
    //     { vmid: 'og:image_tstock', property: 'og:image', content: 'https://geek9.kr/img/logo_big.e0186dc8.jpg'},
    //     { vmid: 'og:url_tstock', property: 'og:url', content: 'https://geek9.kr/stock'}
    //   ]
    // })
    return {
    }
  },
  components: {
  },
  mounted() {
    this.api.setPage('stock')
    console.log(this.api.getCookies('page'))
    this.$emit("setMenu", {page:'stock'})
    this.init()
  },
  data(){
    return{
    }
  },
  methods : {
    init() {
      var savedHistory = JSON.parse(localStorage.getItem("history"))
      if (savedHistory) {
        savedHistory.forEach((item) => {
          this.addToHistory(item.stockCode, item.price)
        })
      }
    },
    getStockPrice() {
      var stockCode = document.getElementById("stockCode").value
      var xhr = new XMLHttpRequest()
      xhr.open("GET", this.api.BACKEND_URL + "/geek9?type=presPrice&code=" + stockCode, true)
      xhr.onreadystatechange = () => {
        if (xhr.readyState == 4 && xhr.status == 200) {
          var price = xhr.responseText
          document.getElementById("stockPrice").textContent = "현재 주식 가격: " + price
          this.addToHistory(stockCode, price)
        }
      }
      xhr.send()
    },
    addToHistory(stockCode, price) {
      var historyList = document.getElementById("historyList")
      var existingItem = document.querySelector(`#historyList li[data-stockcode="${stockCode}"]`)
      if (!existingItem) {
        var listItem = document.createElement("li")
        listItem.textContent = "종목 코드: " + stockCode + ", 현재가: " + price
        listItem.setAttribute("data-stockcode", stockCode)

        // 삭제 버튼 추가
        var deleteButton = document.createElement("button")
        deleteButton.textContent = "삭제"
        deleteButton.classList.add("deleteButton")
        deleteButton.onclick = () => {
          this.deleteHistoryItem(stockCode)
        }
        listItem.appendChild(deleteButton)

        historyList.appendChild(listItem)

        // 로컬 스토리지에 히스토리 저장 (최대 10개까지)
        var history = JSON.parse(localStorage.getItem("history")) || []
        history.push({stockCode: stockCode, price: price})
        if (history.length > 10) {
          history.shift() // 최근 항목부터 삭제하여 최대 10개 유지
        }
        localStorage.setItem("history", JSON.stringify(history))
      }
    },
    deleteHistoryItem(stockCode) {
      var historyList = document.getElementById("historyList")
      var listItem = document.querySelector(`#historyList li[data-stockcode="${stockCode}"]`)
      if (listItem) {
        historyList.removeChild(listItem)

        // 로컬 스토리지에서 해당 이력 제거
        var history = JSON.parse(localStorage.getItem("history")) || []
        history = history.filter((item) => {
          return item.stockCode !== stockCode
        })
        localStorage.setItem("history", JSON.stringify(history))
      }
    },
    resetLocalStorage() {
      localStorage.removeItem("history")
      document.getElementById("historyList").innerHTML = ""
    }
  }
}
</script>

<style>

#stock {
  height: 100vh;
  width:100%;
  margin: 0;
  text-align: center; 
}

.chart-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.stock-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

#stockCode {
  padding: 10px;
  width: 200px;
  font-size: 16px;
}

#btnSearch {
  padding: 10px 20px;
  font-size: 16px;
  background-color: #007bff;
  border: none;
  color: #fff;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

#btnSearch:hover {
  background-color: #0056b3;
}

#stockPrice {
  font-size: 20px;
  margin-top: 20px;
}

#history {
  flex: 1;
  background-color: #fff;
  border: 1px solid #ddd;
  padding: 20px;
  margin-right: 10px;
}

#history h2 {
  font-size: 20px;
  margin-bottom: 10px;
  border-bottom: 1px solid #ddd;
  padding-bottom: 10px;
}

#historyList {
  list-style: none;
  padding: 0;
  margin: 0;
}

#historyList li {
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #eee;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.deleteButton {
  background-color: #dc3545;
  color: #fff;
  border: none;
  padding: 5px 10px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.deleteButton:hover {
  background-color: #c82333;
}

#resetButton {
  background-color: #6c757d;
  color: #fff;
  border: none;
  padding: 5px 10px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

#resetButton:hover {
  background-color: #5a6268;
}

#chartContainer {
  flex: 1;
  background-color: #fff;
  border: 1px solid #ddd;
  padding: 20px;
  margin-bottom: 10px;
}
</style>
